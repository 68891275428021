@import "../public/variables";

.WebInterview {
  color: $color-dark-grey;
  letter-spacing: 0.05rem;

  &__Label {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: 700;
    font-size: 18px;

    span {
      font-size: 14px;

      &.text-red,
      &.text-blue {
        position: relative;
        top: 2px;
        display: block;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        padding: 0 10px;
        white-space: nowrap;
      }

      &.text-red {
        background-color: #ffeaef;
        color: #e82957;
      }

      &.text-blue {
        background-color: #edf0f9;
        color: #003f8f;
      }
    }
  }

  &__Title {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
  }

  &__TextEmphasis {
    font-size: 24px;
  }

  &__Note {
    white-space: pre-wrap;
  }

  &__Button {
    border-radius: 8px;
    border: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(87.63deg, #394d97 1.54%, #368fe2 99.44%);
    box-shadow: 0 4px 24px rgba(55, 138, 221, 0.13);
    color: white;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  &__partition {
    height: 1px;
    width: 100%;
    margin: 0 16px;
    background-color: rgba($color-blue, 0.65);
  }

  &__input-row {
    display: flex;
    align-items: baseline;
  }

  &__ErrorMessage {
    font-size: 12px;
    color: $color-orange;
  }

  &__input-unit {
    margin: 0 8px;
    font-weight: 700;
  }

  &__input-small {
    border-radius: 0.5rem;
    border: 1.5px solid $input-border-color;
    padding: 0.375rem 0.75rem;
    height: 56px;
    width: 70px;
    text-align: center;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  ._year {
    width: 85px;
  }

  &__input-oneLiner {
    border-radius: 0.5rem;
    border: 1.5px solid $input-border-color;
    padding: 0.375rem 0.75rem;
    min-height: 56px;
    width: 100%;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  &__input-textarea {
    border-radius: 0.5rem;
    border: 1.5px solid $input-border-color;
    padding: 0.375rem 0.75rem;
    min-height: 120px;
    width: 100%;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  &__input-image {
    position: relative;
    border-radius: 0.5rem;
    width: 100%;
    height: 180px;
    color: #bdbdbd;
    text-align: center;

    img {
      &.WebInterview__image-preview,
      &.WebInterview__image-uploading {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
      }

      &.WebInterview__image-preview {
        border: 2px solid $color-dark-grey;
      }
    }

    &_background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &__input-select {
    position: relative;

    select {
      min-height: 56px;
      width: 100%;
      padding: 0.375rem 0.75rem;
      border-radius: 0.5rem;
      border: 1.5px solid $input-border-color;
      appearance: none;
      font-size: 18px;

      &:focus {
        outline: none;
      }
    }

    &::after {
      position: absolute;
      content: "";
      z-index: 2;
      right: 20px;
      top: 50%;
      margin-top: -7px;
      width: 10px;
      height: 10px;
      border-top: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
      transform: rotate(135deg);
    }
  }

  &__input-budge {
    color: white;
    background-color: $color-red;
    position: absolute;
    top: -16px;
    right: -20px;
    height: 88px;
    width: 88px;
    border-radius: 50%;
    transform: rotate(7.87deg);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin-bottom: 0;
      line-height: 20px;
    }
  }

  &__input-radio {
    appearance: none;
    visibility: hidden;

    &_label {
      position: relative;
      min-height: 56px;
      margin-bottom: 0;
      padding: 12px 12px 12px 44px;
      width: 100%;
      border-radius: 0.5rem;
      border: 1.5px solid $input-border-color;
      font-size: 18px;
      display: flex;
      align-items: center;
      background-color: white;
    }

    &:not(:checked) + label {
      &::before {
        position: absolute;
        content: "";
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 2px solid #97a0bc;
      }
    }

    &:checked + label {
      background-color: $color-pale-blue;

      &::before,
      &::after {
        position: absolute;
        content: "";
        transform: translateY(-50%);
        border-radius: 100%;
        top: 50%;
      }

      &::before {
        left: 16px;
        width: 20px;
        height: 20px;
        border: 2px solid $color-blue;
      }

      &::after {
        left: 21px;
        width: 10px;
        height: 10px;
        background-color: $color-blue;
      }
    }
  }

  &__Circle {
    position: relative;
    display: inline-block;
    color: white;
    font-weight: bold;
    font-size: 50px;
    top: 0;
    left: 0;
    height: 76px;
    width: 76px;
    margin-left: -12px;
    border-radius: 100%;
    background-color: $color-blue;
  }

  &__bg-pink {
    // 親無視でで横いっぱいに広げる
    margin: 0 -15px;
    padding: 0 15px;
    background-color: rgba($color: $color-red, $alpha: 0.05);
  }

  .badge {
    &.badge-red {
      background-color: #ffeaef;
      color: #e82957;
    }

    &.badge-gray {
      background-color: #eaeaea;
      color: #343a40;
    }
  }

  .PrescriptionPriceList {
    .overflow-y-hidden {
      overflow-y: hidden;
    }

    .min-w-75 {
      min-width: 75%;
    }

    .border-bottom-dotted {
      border-bottom: 2px dotted;
    }

    .bg-light-blue {
      background-color: #f2f8ff;
    }
  }
}
