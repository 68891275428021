.customer-app {
  max-width: 480px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #eee;
  font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, sans-serif;
  font-weight: bold;
  line-height: 24px;
  color: #423635;

  .logo {
    width: fit-content;
    margin: 0 auto;
    padding: 64px 0;
  }

  label {
    margin: 0;
    font-size: 16px;
  }

  .form-group {
    margin-bottom: 25px;
  }

  .form-group#user_id {
    margin-bottom: 16px;
  }

  .form-control {
    height: 48px;
    border-radius: 0%;
    border: 1px solid #999;
    background: #f8f8f8;
  }

  .form-control::placeholder {
    color: #999;
  }

  p#description {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .text-muted {
    margin-top: 3px;
    font-size: 14px;
    font-weight: 500;
    color: #423635 !important;
    line-height: 21px;
  }

  .error_msg h2 {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    padding-top: 12px;
  }

  .btn {
    font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #423635;
  }

  .btn-app {
    width: 100%;
    background: linear-gradient(133.55deg, #f8f8f8 0%, #e8e8e8 100%);
    box-shadow:
      inset 2px 2px 2px #fff,
      inset -1px -1px 2px #b9b9b9,
      inset -1px -1px 0 2px #fafafa,
      inset 0 0 8px 3px #e7e7e7,
      -3px -3px 8px rgba($color: #fff, $alpha: 0.5),
      5px 5px 10px #b9b9b9;
    border-radius: 16px;
    border-width: 0;
    padding-top: 24px;
    padding-bottom: 21px;
  }

  .btn_wrap {
    color: #fff;
    position: relative;
    line-height: 2.8em;
    width: 100%;
    transition: 0.5s;
    margin: 0 auto;
    padding: 15px 0;
  }

  .btn_wrap::after {
    transition: 0.3s;
    content: "";
    width: 1em;
    height: 1em;
    border-top: 2px solid #f64f2b;
    border-right: 2px solid #f2ab40;
    border-image: linear-gradient(to left, #f2ab40 0%, #f64f2b 50%);
    border-image-slice: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    margin: auto;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .links {
    width: fit-content;
    margin: 0 auto;
    padding-top: 12px;
  }

  .links a {
    color: #1875e2;
  }

  .caution-icon {
    margin: 0 auto;
    width: 48px;
    height: 48px;
  }

  .description {
    color: #423635;
    margin-top: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}
