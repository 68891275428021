.thanksPurchase {
  h3 {
    line-height: 1.5;
  }

  p {
    &.small {
      color: #636569;
    }
  }

  .alert-danger {
    background-color: #fff2f2;
    border: 1px solid #fbd0d0;
  }

  ol {
    li {
      background-color: rgba(0, 0, 0, 0.05);
      margin-bottom: 10px;

      label {
        padding-right: 10px;
      }
    }
  }
}
