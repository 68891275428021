.schedulable-calender {
  border-bottom: 1px solid #c9c9c9;

  .bg-light-grey {
    background-color: #f0f0f0;
  }

  .bg-grey {
    background-color: rgba(#c4c4c4, 0.25);
  }

  .border-grey-top {
    border-top: 1px solid #c9c9c9;
  }

  ._selected {
    background-color: rgba(#e1583a, 0.1);
  }

  &__header {
    &__name {
      width: 12.5%;
    }

    &__title {
      width: 87.5%;
    }
  }

  &__time {
    width: 12.5%;
  }

  &__content {
    width: 87.5%;
  }

  &__column {
    flex: 1;
  }

  &__date--fri {
    background-color: rgba(#007cd7, 0.1);

    &__text {
      color: #007cd7;
    }
  }

  &__date--sun {
    background-color: rgba(#e1583a, 0.1);

    &__text {
      color: #e1583a;
    }
  }

  &__state--unable {
    color: #b3b3b3;
  }
}
